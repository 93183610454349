import {setCookie} from "@@/js/utils/cookies.js";
import {delay} from '@@/js/utils/delay';

const announcement = document.querySelector('.announcement');

if (announcement) {
  const btn = announcement.querySelector('button');

  btn.addEventListener('click', () => {
    setCookie('announcement', announcement.dataset.id)
    announcement.classList.add('is-to-hide')
    delay(300).then(() => {
      announcement.remove()
    });
  });
}


