const observer = document.querySelector('[data-header-observer]');
const menuChildren = document.querySelectorAll('.menu-item-has-children > a');

observer && new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      document.body.classList.remove('is-scrolled');
    } else {
      document.body.classList.add('is-scrolled');
    }
  });
}).observe(observer);

menuChildren.forEach(el => {
  el.addEventListener('click', function(e) {
    e.preventDefault();
    menuChildren.forEach(child => child.classList.remove('is-open'));
    el.classList.toggle('is-open');
  });
});