<template>
  <div class="wine-club">
    <tabs />
    <transition name="fade" mode="out-in">
      <step-type v-if="currentActiveTab === STEP_TYPE" />
      <step-frequency v-if="currentActiveTab === STEP_FREQUENCY" />
      <step-quantity v-if="currentActiveTab === STEP_QUANTITY" />
      <step-selection v-if="currentActiveTab === STEP_SELECTION" />
      <step-summary v-if="currentActiveTab === STEP_SUMMARY" />
    </transition>
    <prev-step />
    <prepack-details-popup />
  </div>
</template>

<script>
import Tabs from "./components/Tabs.vue";
import PrevStep from "./components/PrevStep.vue";
import StepType from "./components/StepType.vue";
import StepFrequency from "./components/StepFrequency.vue";
import StepQuantity from "./components/StepQuantity.vue";
import StepSelection from "./components/StepSelection.vue";
import StepSummary from "./components/StepSummary.vue";
import PrepackDetailsPopup from "./components/PrepackDetailsPopup.vue";

import {
  STEP_TYPE,
  STEP_FREQUENCY,
  STEP_QUANTITY,
  STEP_SELECTION,
  STEP_SUMMARY,
} from "./store";

export default {
  name: "WineClub",
  props: {
    frequencyOptions: {
      type: Array,
      required: true,
    },
    quantityOptions: {
      type: Array,
      required: true,
    },
    prepackProductOptions: {
      type: [Array, Boolean],
      required: true,
    },
    mixedProductOptions: {
      type: Array,
      required: true,
    },
    commonSettings: {
      type: Object,
      required: true
    }
  },
  components: {
    Tabs,
    PrevStep,
    StepType,
    StepFrequency,
    StepQuantity,
    StepSelection,
    StepSummary,
    PrepackDetailsPopup
  },
  data() {
    return {
      STEP_TYPE,
      STEP_FREQUENCY,
      STEP_QUANTITY,
      STEP_SELECTION,
      STEP_SUMMARY,
    };
  },
  computed: {
    currentActiveTab() {
      return this.$store.state.selectedTypeStepsSequence[this.$store.state.currentActiveTabIndex];
    },
  },
  watch: {
    currentActiveTab() {
      const el = document.querySelector(".wine-club");
      window.scrollTo({
        top: el.offsetTop,
      });
    }
  },
  mounted() {
    this.prepackProductOptions && this.$store.commit("setPrepackProductOptions", this.prepackProductOptions);
    this.$store.commit("setMixedProductOptions", this.mixedProductOptions);
    this.$store.commit("setFrequencyOptions", this.frequencyOptions);
    this.$store.commit("setQuantityOptions", this.quantityOptions);
    this.$store.commit("setCommonSettings", this.commonSettings);
  }
};
</script>
