<template>
  <div class="wine-club-tabs">
    <div class="wine-club-tabs__tabs">
      <button
        class="wine-club-tabs__tabs-tab"
        :class="{
          'is-active': currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_TYPE),
          'is-completed': currentStepIndex > selectedTypeStepsSequence.indexOf(STEP_TYPE)
        }"
        @click="handleTabChange(STEP_TYPE)"
        :disabled="isTabDisabled(STEP_TYPE)"
      >
        <span class="wine-club-tabs__tabs-tab-label">
          Step {{ selectedTypeStepsSequence.indexOf(STEP_TYPE) + 1 }}: Type
        </span>
        <span class="wine-club-tabs__tabs-tab-value">
          {{ selectedTypeLabel }}
        </span>
      </button>
      <button
        v-if="selectedType === TYPE_MIXED && !isFoundationMember"
        class="wine-club-tabs__tabs-tab"
        :class="{
          'is-active': currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_FREQUENCY),
          'is-completed': currentStepIndex > selectedTypeStepsSequence.indexOf(STEP_FREQUENCY)
        }"
        @click="handleTabChange(STEP_FREQUENCY)"
        :disabled="isTabDisabled(STEP_FREQUENCY)"
      >
        <span class="wine-club-tabs__tabs-tab-label">
          Step {{ selectedTypeStepsSequence.indexOf(STEP_FREQUENCY) + 1 }}: Frequency
        </span>
        <span class="wine-club-tabs__tabs-tab-value">
          Every {{ selectedFrequency }} months
        </span>
      </button>
      <button
        class="wine-club-tabs__tabs-tab"
        :class="{
          'is-active': currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_QUANTITY),
          'is-completed': currentStepIndex > selectedTypeStepsSequence.indexOf(STEP_QUANTITY)
        }"
        @click="handleTabChange(STEP_QUANTITY)"
        :disabled="isTabDisabled(STEP_QUANTITY)"
      >
        <span class="wine-club-tabs__tabs-tab-label">
          Step {{ selectedTypeStepsSequence.indexOf(STEP_QUANTITY) + 1 }}: Quantity
        </span>
        <span class="wine-club-tabs__tabs-tab-value">
          {{ selectedQuantity }} bottles
        </span>
      </button>
      <button
        v-if="selectedType === TYPE_MIXED"
        class="wine-club-tabs__tabs-tab"
        :class="{
          'is-active': currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_SELECTION),
          'is-completed': currentStepIndex > selectedTypeStepsSequence.indexOf(STEP_SELECTION)
        }"
        @click="handleTabChange(STEP_SELECTION)"
        :disabled="isTabDisabled(STEP_SELECTION)"
      >
        <span class="wine-club-tabs__tabs-tab-label">
          Step {{ selectedTypeStepsSequence.indexOf(STEP_SELECTION) + 1 }}: Selection
        </span>
        <span class="wine-club-tabs__tabs-tab-value">
          Personal
        </span>
      </button>
      <button
        class="wine-club-tabs__tabs-tab"
        :class="{
          'is-active': currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_SUMMARY)
        }"
        @click="handleTabChange(STEP_SUMMARY)"
        :disabled="isTabDisabled(STEP_SUMMARY)"
      >
        <span class="wine-club-tabs__tabs-tab-label">
          Step {{ selectedTypeStepsSequence.indexOf(STEP_SUMMARY) + 1 }}: Summary
        </span>
      </button>
    </div>
      <div v-if="currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_TYPE)" class="wine-club-tabs__mobile-title">
        Step {{ selectedTypeStepsSequence.indexOf(STEP_TYPE) + 1 }}: Type
      </div>
      <div v-if="selectedType === TYPE_MIXED && currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_FREQUENCY)" class="wine-club-tabs__mobile-title">
        Step {{ selectedTypeStepsSequence.indexOf(STEP_FREQUENCY) + 1 }}: Frequency
      </div>
      <div v-if="currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_QUANTITY)" class="wine-club-tabs__mobile-title">
        Step {{ selectedTypeStepsSequence.indexOf(STEP_QUANTITY) + 1 }}: Quantity
      </div>
      <div v-if="selectedType === TYPE_MIXED && currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_SELECTION)" class="wine-club-tabs__mobile-title">
        Step {{ selectedTypeStepsSequence.indexOf(STEP_SELECTION) + 1 }}: Selection
      </div>
      <div v-if="currentActiveTabIndex === selectedTypeStepsSequence.indexOf(STEP_SUMMARY)" class="wine-club-tabs__mobile-title">
        Step {{ selectedTypeStepsSequence.indexOf(STEP_SUMMARY) + 1 }}: Summary
      </div>
  </div>
</template>

<script>
import {
  STEP_TYPE,
  STEP_FREQUENCY,
  STEP_QUANTITY,
  STEP_SELECTION,
  STEP_SUMMARY,
  TYPE_PREPACK,
  TYPE_MIXED
} from "../store";

export default {
  data() {
    return {
      TYPE_MIXED,
      STEP_TYPE,
      STEP_FREQUENCY,
      STEP_QUANTITY,
      STEP_SELECTION,
      STEP_SUMMARY,
    };
  },
  computed: {
    commonSettings() {
      return this.$store.state.commonSettings;
    },
    isFoundationMember() {
      return this.commonSettings.isFoundationMember;
    },
    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    currentActiveTabIndex() {
      return this.$store.state.currentActiveTabIndex;
    },
    selectedType() {
      return this.$store.state.selectedType;
    },
    selectedTypeStepsSequence() {
      return this.$store.state.selectedTypeStepsSequence;
    },
    selectedFrequency() {
      return this.$store.state.selectedFrequency;
    },
    selectedQuantity() {
      return this.$store.state.selectedQuantity;
    },
    selectedProduct() {
      return this.$store.state.selectedProduct;
    },
    selectedTypeLabel() {
      return this.selectedType === TYPE_MIXED ? "Personal Selection" : this.selectedProduct && this.selectedProduct.title;
    }
  },
  methods: {
    isTabDisabled(step) {
      return this.currentStepIndex < this.selectedTypeStepsSequence.indexOf(step);
    },
    handleTabChange(step) {
      if (this.isTabDisabled(step)) {
        return
      }
      this.$store.commit('setCurrentActiveTabIndex', this.selectedTypeStepsSequence.indexOf(step));
    }
  },
};
</script>