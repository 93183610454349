<template>
  <div class="wine-club-prev-step">
    <transition name="fade">
      <button
        v-if="isVisible"
        class="wine-club-prev-step__button button is-alt"
        @click="handlePrevStepClick()"
      >
        Back to step {{ currentActiveTabIndex }}: {{ prevStepLabel }}
      </button>
    </transition>
  </div>
</template>

<script>
import {
  STEP_TYPE,
  STEP_FREQUENCY,
  STEP_QUANTITY,
  STEP_SELECTION,
  STEP_SUMMARY,
  TYPE_PREPACK,
  TYPE_MIXED,
  STEP_LABEL
} from "../store";

export default {
  data() {
    return {
      STEP_TYPE,
      STEP_FREQUENCY,
      STEP_QUANTITY,
      STEP_SELECTION,
      STEP_SUMMARY,
    };
  },
  computed: {
    currentStepIndex() {
      return this.$store.state.currentStepIndex;
    },
    currentActiveTabIndex() {
      return this.$store.state.currentActiveTabIndex;
    },
    selectedTypeStepsSequence() {
      return this.$store.state.selectedTypeStepsSequence;
    },
    isVisible() {
      return this.currentActiveTabIndex > 0;
    },
    prevStepLabel() {
      return this.currentActiveTabIndex > 0 && STEP_LABEL[this.selectedTypeStepsSequence[this.currentActiveTabIndex - 1]]
    }
  },
  methods: {
    isTabDisabled(step) {
      return this.currentStepIndex < this.selectedTypeStepsSequence.indexOf(step);
    },
    handlePrevStepClick() {
      this.$store.commit('setCurrentActiveTabIndex', this.currentActiveTabIndex - 1);
    }
  },
};
</script>