<template>
  <div
    class="mixed-selection-illustraion"
    :class="{
      'mixed-selection-illustraion--multi-row': rows.length > 1
    }"
  >
    <div v-for="(row, rowIndex) in rows" :key="rowIndex" class="mixed-selection-illustraion__row">
      <div v-for="(img, colIndex) in row" :key="colIndex" class="mixed-selection-illustraion__col">
        <img :src="img" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    quantity: {
      type: Number,
      required: true
    },
    images: {
      type: Array,
      required: true
    }
  },
  computed: {
    rows() {
      const perRow = 6;

      const rowsNum = this.quantity / perRow;
      const result = [];
      for (let i = 0; i < rowsNum; i++) {
        const rowContent = [];
        let counter = 0;
        while (counter < perRow) {
          if (this.images[perRow * i + counter]) {
            rowContent.push(this.images[perRow * i + counter]);
          } else {
            rowContent.push(`${window.TANTALUS.asssets_base_url}/images/bottle-placeholder.png`);
          }
          counter++;
        }
        result.push(rowContent);
      }
      return result;
    }
  }
};
</script>