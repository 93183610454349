import Vuex from "vuex";
import Vue from "vue";

import { groupBy } from "@@/js/utils/utils";
import {addToCart} from '@@/js/components/cart';

Vue.use(Vuex);

export const TYPE_PREPACK = "TYPE_PREPACK";
export const TYPE_MIXED = "TYPE_MIXED";

export const STEP_TYPE = "STEP_TYPE";
export const STEP_FREQUENCY = "STEP_FREQUENCY";
export const STEP_QUANTITY = "STEP_QUANTITY";
export const STEP_SELECTION = "STEP_SELECTION";
export const STEP_SUMMARY = "STEP_SUMMARY";

export const STEP_LABEL = {
  [STEP_TYPE]: "Type",
  [STEP_FREQUENCY]: "Frequency",
  [STEP_QUANTITY]: "Quantity",
  [STEP_SELECTION]: "Selection",
  [STEP_SUMMARY]: "Summary",
};

export const STEPS_SEQUENCE_MIXED = [
  STEP_TYPE,
  STEP_FREQUENCY,
  STEP_QUANTITY,
  STEP_SELECTION,
  STEP_SUMMARY,
];

export const STEPS_SEQUENCE_MIXED_FOUNDATION_MEMBER = [
  STEP_TYPE,
  STEP_QUANTITY,
  STEP_SELECTION,
  STEP_SUMMARY,
];

export const STEPS_SEQUENCE_PREPACK = [
  STEP_TYPE,
  STEP_QUANTITY,
  STEP_SUMMARY,
];

export default new Vuex.Store({
  state: {
    isLoading: false,
    currentStepIndex: 0,
    currentActiveTabIndex: 0,
    selectedType: null,
    selectedTypeStepsSequence: STEPS_SEQUENCE_PREPACK,
    selectedFrequency: null,
    selectedQuantity: null,
    selectedProduct: null,
    selectedMixedContent: [],
    frequencyOptions: [],
    quantityOptions: [],
    prepackProductOptions: [],
    mixedProductOptions: [],
    mixedContentOptions: [],
    commonSettings: {},
    matchingProducts: [],
    prepackDetailsPopupProduct: null
  },

  mutations: {
    setFrequencyOptions(state, frequencyOptions) {
      state.frequencyOptions = frequencyOptions;
    },
    setQuantityOptions(state, quantityOptions) {
      state.quantityOptions = quantityOptions;
    },
    setPrepackProductOptions(state, products) {
      state.prepackProductOptions = products;
    },
    setMixedProductOptions(state, products) {
      state.mixedProductOptions = products;
    },
    setCommonSettings(state, settings) {
      state.commonSettings = settings;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setSelectedType(state, type) {
      state.selectedType = type;
    },
    setSelectedTypeStepsSequence(state, sequence) {
      state.selectedTypeStepsSequence = sequence;
    },
    setSelectedFrequency(state, option) {
      state.selectedFrequency = option;
    },
    setSelectedQuantity(state, option) {
      state.selectedQuantity = option;
    },
    setCurrentStepIndex(state, index) {
      state.currentStepIndex = index;
    },
    setCurrentActiveTabIndex(state, index) {
      state.currentActiveTabIndex = index;
    },
    setMixedContentOptions(state, options) {
      state.mixedContentOptions = options;
    },
    setSelectedProduct(state, product) {
      state.selectedProduct = product;
    },
    setPrepackDetailsPopupProduct(state, product) {
      state.prepackDetailsPopupProduct = product;
    },
    clearSelectedMixedContent(state) {
      state.selectedMixedContent = [];
    },
    addToSelectedMixedContent(state, product) {
      state.selectedMixedContent = [...state.selectedMixedContent, product];
    },
    removeFromSelectedMixedContent(state, product) {
      const i = state.selectedMixedContent.findIndex(
        (p) => p.variation_id === product.variation_id
      );
      state.selectedMixedContent = [
        ...state.selectedMixedContent.slice(0, i),
        ...state.selectedMixedContent.slice(i + 1),
      ];
    },
  },

  actions: {
    selectType({ state, commit, dispatch }, { type, product }) {
      const selectedStepsSequence =
        type === TYPE_MIXED ? (state.commonSettings.isFoundationMember ? STEPS_SEQUENCE_MIXED_FOUNDATION_MEMBER : STEPS_SEQUENCE_MIXED) : STEPS_SEQUENCE_PREPACK;

      commit("setSelectedTypeStepsSequence", selectedStepsSequence);
      commit(
        "setCurrentStepIndex",
        selectedStepsSequence.indexOf(STEP_TYPE) + 1
      );
      commit(
        "setCurrentActiveTabIndex",
        selectedStepsSequence.indexOf(STEP_TYPE) + 1
      );
      if (product) {
        commit("setSelectedProduct", product);
        // Prepacked products now come with a predefined default single frequency
        if (type === TYPE_PREPACK) {
          commit("setSelectedFrequency", product.variations[0].frequency);
        }
      }
      commit("setSelectedType", type);
      commit("clearSelectedMixedContent");
      dispatch("updateMixedProductPackageContent");
    },
    selectFrequency({ state, commit, dispatch }, option) {
      commit(
        "setCurrentStepIndex",
        state.selectedTypeStepsSequence.indexOf(STEP_FREQUENCY) + 1
      );
      commit(
        "setCurrentActiveTabIndex",
        state.selectedTypeStepsSequence.indexOf(STEP_FREQUENCY) + 1
      );
      commit("setSelectedFrequency", option);
      commit("clearSelectedMixedContent");
      dispatch("updateMixedProductPackageContent");
    },
    selectQuantity({ state, commit, dispatch }, option) {
      commit(
        "setCurrentStepIndex",
        state.selectedTypeStepsSequence.indexOf(STEP_QUANTITY) + 1
      );
      commit(
        "setCurrentActiveTabIndex",
        state.selectedTypeStepsSequence.indexOf(STEP_QUANTITY) + 1
      );
      commit("setSelectedQuantity", option);

      if (state.commonSettings.isFoundationMember) {
        commit("setSelectedFrequency", 12);
      }

      commit("clearSelectedMixedContent");
      dispatch("updateMixedProductPackageContent");
    },
    confirmMixedSelection({ commit, state }) {
      commit(
        "setCurrentStepIndex",
        state.selectedTypeStepsSequence.indexOf(STEP_SELECTION) + 1
      );
      commit(
        "setCurrentActiveTabIndex",
        state.selectedTypeStepsSequence.indexOf(STEP_SELECTION) + 1
      );
    },
    updateMixedProductPackageContent({ commit, state }, type) {
      if (
        !state.selectedType ||
        state.selectedType !== TYPE_MIXED ||
        !state.selectedFrequency ||
        !state.selectedQuantity
      ) {
        return Promise.resolve(true);
      }
      const product = state.mixedProductOptions.find(
        (p) => p.container_size === state.selectedQuantity
      );
      if (!product) {
        console.log("Invalid mixed product id for selected quantity");
        return Promise.reject();
      }
      commit("setSelectedProduct", product);
      commit("setIsLoading", true);

      const url = new URLSearchParams({
        'action': 'get_mixed_product_package_content',
        'product_id': product.product_id,
      }).toString();

      fetch(`${window.ajaxApp.ajaxUrl}?${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
            commit("setMixedContentOptions", data.data);
            commit("clearSelectedMixedContent");
        } else {
          console.error(data.data);
        }
      })
      .finally(() => {
        commit("setIsLoading", false);
      });
    },
    addMixedProduct({ commit, state }, product) {
      commit("addToSelectedMixedContent", product);
    },
    removeMixedProduct({ commit, state }, product) {
      commit("removeFromSelectedMixedContent", product);
    },
    activatePrepackDetailsPopup({ commit }, product) {
      commit("setPrepackDetailsPopupProduct", product);
    },
    deactivatePrepackDetailsPopup({ commit }) {
      commit("setPrepackDetailsPopupProduct", null);
    },
    addToCart({ commit, state }, product) {
      const $html = $("html");
      if ($html.hasClass("is-product-loading")) {
        return;
      }
      if (state.selectedType === TYPE_PREPACK) {
        const variation = state.selectedProduct.variations.find(
          (v) =>
            v.quantity === state.selectedQuantity &&
            v.frequency === state.selectedFrequency
        );
        if (!variation) {
          console.log("Invalid variation for prepacked product");
          return;
        }
        addToCart(variation.variation_id, 1)
      } else {
        const params = {
          [`convert_to_sub_${state.selectedProduct.product_id}`]: state.selectedProduct.frequencies[state.selectedFrequency]
        };
        const groupedMixedContent = groupBy(
          state.selectedMixedContent,
          "variation_id"
        );
        Object.keys(groupedMixedContent).forEach((k) => {
          params[`mnm_quantity[${groupedMixedContent[k][0].variation_id}]`] = groupedMixedContent[k].length;
        });

        addToCart(state.selectedProduct.product_id, 1, new URLSearchParams(params).toString())
      }
    },
  },
});
