<template>
  <div class="container">
    <div class="wine-club-step-summary">
      <div
        class="wine-club-step-summary__content"
      >
        <h3 class="wine-club-step-summary__title is-h3">Summary</h3>
        <img
          v-if="selectedType === TYPE_PREPACK"
          class="wine-club-card__img"
          :src="selectedPrepackVariation?.image"
          :alt="selectedProduct.title"
          @load="handleImageLoaded()"
          :class="{
            'wine-club-card__img--loaded': isImageLoaded
          }"
        />
        <div class="wine-club-step-summary__mixed-selection-illustration-container">
          <mixed-selection-illustration
            v-if="selectedType === TYPE_MIXED"
            class="wine-club-step-summary__mixed-selection-illustration"
            :quantity="selectedProduct.container_size"
            :images="selectedMixedContentImages"
          />
        </div>
        <div class="columns">
          <div class="wine-club-step-summary__sum">
            <div class="wine-club-step-summary__sum-label">
              Type
            </div>
            <div class="wine-club-step-summary__sum-title">
              {{ selectionLabel }}
            </div>
            <div class="wine-club-step-summary__sum-label">
              Frequency
            </div>
            <div class="wine-club-step-summary__sum-title">
              Every {{ selectedFrequency }} months
            </div>
            <div class="wine-club-step-summary__sum-label">
              Quantity
            </div>
            <div class="wine-club-step-summary__sum-title">
              {{ selectedQuantity }} bottles
            </div>
          </div>
          <div
            v-if="selectedType === TYPE_PREPACK"
            class="column is-7"
          >
            <div class="wine-club-step-summary__sum-selection">
              Selection
            </div>
            <div class="wine-club-card__package-content wine-club-package-content">
              <div
                v-for="(item, index) in selectedPrepackContent"
                :key="index"
                class="wine-club-package-content__item"
              >
                <div class="wine-club-package-content__item-label">
                  {{ item.subscription_package_content_item_title || '-' }}
                </div>
                <div class="wine-club-package-content__item-quantity">
                  {{ item.subscription_package_content_item_quantity ? `x${item.subscription_package_content_item_quantity}` : '' }}
                </div>
              </div>
              <div class="wine-club-package-content__item wine-club-package-content__item--total">
                <div class="wine-club-package-content__item-label">
                  Total per shipment:
                </div>
                <div>
                  <div class="wine-club-package-content__item-regular-price"></div>
                  <div class="wine-club-package-content__item-package-price">${{ selectedPrepackVariation && selectedPrepackVariation.price }}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="selectedType === TYPE_MIXED"
            class="column is-7"
          >
            <div class="wine-club-step-summary__sum-label">
              Selection
            </div>
            <div class="wine-club-card__package-content wine-club-package-content">
                <div
                  v-for="(group, index) in groupedMixedContent"
                  :key="index"
                  class="wine-club-package-content__item"
                >
                  <div class="wine-club-package-content__item-label">
                    {{ group.product.title }} {{ group.product.vintage && ' - ' + group.product.vintage.name }}
                  </div>
                  <div class="wine-club-package-content__item-quantity">
                    x{{ group.quantity }}
                  </div>
                  <div class="wine-club-package-content__item-regular-price">
                    ${{ group.product.regular_price ? group.product.regular_price.toFixed(2) : '' }}
                  </div>
                  <div class="wine-club-package-content__item-package-price">
                    ${{ group.product.price ? group.product.price.toFixed(2) : '' }}
                  </div>
                </div>
                <div key="total" class="wine-club-package-content__item wine-club-package-content__item--total">
                  <div class="wine-club-package-content__item-label">
                    Total per shipment:
                  </div>
                  <div>
                    <div class="wine-club-package-content__item-regular-price"></div>
                    <div class="wine-club-package-content__item-package-price">${{ totalMixedPrice }}</div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div v-if="!isFoundationMember" class="wine-club-step-summary__terms">
          Please read the <a class="is-active-border-link" :href="termsUrl" target="_blank">Wine Club Terms and Conditions</a> prior to signing up
        </div>
        <div v-else>
          Please sign up to confirm your Foundation Wine Club membership renewal allocation and RSVP for the annual FWC Dinner.
        </div>
        <button class="wine-club-step-summary__submit button" @click="addToCart" :disabled="isLoading">
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_MIXED, TYPE_PREPACK } from "../store";
import { groupBy } from "@@/js/utils/utils";
import MixedSelectionIllustration from "./MixedSelectionIllustration.vue";

export default {
  components: {
    MixedSelectionIllustration
  },
  data() {
    return {
      TYPE_MIXED,
      TYPE_PREPACK,
      isImageLoaded: false
    };
  },
  computed: {
    termsUrl() {
      return window.TANTALUS.wine_club_terms_url;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    commonSettings() {
      return this.$store.state.commonSettings;
    },
    isFoundationMember() {
      return this.commonSettings.isFoundationMember;
    },
    selectedType() {
      return this.$store.state.selectedType;
    },
    selectedTypeLabel() {
      return this.selectedType === TYPE_PREPACK ? "Winemaker's Selection" : "Personal Selection";
    },
    selectedFrequency() {
      return this.$store.state.selectedFrequency;
    },
    selectedQuantity() {
      return this.$store.state.selectedQuantity;
    },
    selectedProduct() {
      return this.$store.state.selectedProduct;
    },
    selectedPrepackVariation() {
      if (this.selectedType !== TYPE_PREPACK) {
        return null;
      }
      return this.selectedProduct.variations.find(v => v.quantity === this.selectedQuantity && v.frequency === this.selectedFrequency);
    },
    selectedPrepackContent() {
      if (this.selectedType !== TYPE_PREPACK) {
        return [];
      }
      if (!this.selectedPrepackVariation) {
        return [];
      }
      const currentContent = this.selectedPrepackVariation.package_content.find(pc => pc.subscription_package_content_is_current === true);
      if (currentContent) {
        return currentContent.subscription_package_content_items;
      }
      return ;
    },
    selectionLabel() {
      return this.selectedType && this.selectedProduct && (this.selectedType === TYPE_MIXED ? 'Personal Selection' : this.selectedProduct.title)
    },
    selectedMixedContent() {
      return this.$store.state.selectedMixedContent;
    },
    selectedMixedContentImages() {
      return this.$store.state.selectedMixedContent.map(c => c.image_url)
    },
    groupedMixedContent() {
      const groupedMixedContent = groupBy(this.selectedMixedContent, 'variation_id');
      const result = [];
      Object.keys(groupedMixedContent).forEach(k => {
        result.push({
          quantity: groupedMixedContent[k].length,
          product: groupedMixedContent[k][0]
        })
      })
      return result;
    },
    totalMixedPrice() {
      return this.selectedMixedContent.reduce((res, i) => res += i.price, 0).toFixed(2);
    },
    subscriptionColor() {
      return this.selectedType === TYPE_MIXED ? this.commonSettings.mixedColor : this.selectedProduct.color
    }
  },
  methods: {
    handleImageLoaded() {
      this.isImageLoaded = true
    },
    addToCart() {
      if (this.isLoading) {
        return;
      }
      this.$store.dispatch("addToCart");
    },
  },
};
</script>