import {delay} from '@@/js/utils/delay';

const cartClass = '.main-cart'
const counter = '[data-cart-count]'
const removeClass = '.remove'
const formClass = '.woocommerce-cart-form'
const cart = document.querySelector(cartClass);

document.addEventListener('click', e => {
  if (e.target.closest(removeClass)) {
    e.preventDefault()
    removeItem(e.target.href);
  }
})

const updateCounter = (html) => {
  const count = html.querySelector(counter).innerHTML;
  document.querySelector(counter).innerHTML = count;
}

const removeItem = (url) => {
  document.body.classList.add('is-cart-loading');
  fetch(url, {
    method: 'POST',
  })
  .then(response => response.text())
  .then(data => {
    const parser = new DOMParser().parseFromString(data, 'text/html');
    cart.innerHTML = parser.querySelector(cartClass).innerHTML;
    updateCounter(parser);
  })
  .catch(error => {
    console.error(error);
  }).finally(() => {
    document.body.classList.remove('is-cart-loading');
  });
}

const updateCart = () => {
  const cartForm = document.querySelector(formClass);
  const formData = new FormData(cartForm);
  formData.append('update_cart', true);
  document.body.classList.add('is-cart-loading');

  fetch(`/`, {
    method: 'POST',
    body: formData,
  })
  .then(response => response.text())
  .then(data => {
    const parser = new DOMParser().parseFromString(data, 'text/html');
    cart.innerHTML = parser.querySelector(cartClass).innerHTML;
    updateCounter(parser);
  })
  .catch(error => {
    console.error(error);
  }).finally(() => {
    document.body.classList.remove('is-cart-loading');
  });
}

const addToCart = (id, quantity, params) => {
  document.body.classList.add('is-cart-loading');
  const url = new URLSearchParams({
    'add-to-cart': id,
    quantity,
  }).toString();
  fetch(`${ajaxApp.homeUrl}?${url}&${params}`, {
    method: 'POST',
  }).then(response => response.text()).then(data => {
    const parser = new DOMParser().parseFromString(data, 'text/html');
    const cart = document.querySelector(cartClass);
    cart.innerHTML = parser.querySelector(cartClass).innerHTML;
    updateCounter(parser);
    delay(200).then(() => {
      document.body.classList.add('cart-is-open');
    })
  }).catch(error => console.error('Error:', error)).finally(() => {
    document.body.classList.remove('is-cart-loading');
  });
}

export { updateCart, addToCart };
