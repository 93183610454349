<template>
  <div class="container">
    <div class="wine-club-step-type wine-club-cards-container">
      <div
        v-for="o in quantityOptions"
        :key="o"
        class="wine-club-card"
        :class="{ 'wine-club-card--active': selectedQuantity === o }"
      >
        <div class="wine-club-card__content">
          <h3 class="wine-club-card__title is-h3">
            {{ o }} bottles
          </h3>
          <img
            class="wine-club-card__img"
            :class="{
              'wine-club-card__img--loaded': isImageLoaded
            }"
            :src="`${baseAssetsUrl}/images/wine-club/size-${o}.png`"
            :alt="`${o} bottles`"
            @load="handleImageLoaded()"
          />
          <button class="wine-club-card__cta" @click="selectQuantity(o)">
            {{ selectedQuantity === o ? 'Selected' : 'Select' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseAssetsUrl: window.TANTALUS.asssets_base_url,
      isImageLoaded: false
    }
  },
  computed: {
    quantityOptions() {
      return this.$store.state.quantityOptions
    },
    selectedQuantity() {
      return this.$store.state.selectedQuantity
    }
  },
  methods: {
    selectQuantity(option) {
      this.$store.dispatch("selectQuantity", option);
    },
    handleImageLoaded() {
      this.isImageLoaded = true
    }
  },
}
</script>