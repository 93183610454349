<template>
  <transition name="fade" mode="out-in">
    <div key="loading" class="has-text-centered has-marg-top-xxl" v-if="!selectedProduct || isLoading">Loading...</div>
    <div v-if="selectedProduct && !isLoading" key="list" class="step-selection-mixed">
      <div class="step-selection-mixed__selected-panel">
        <h3 class="step-selection-mixed__selected-panel-title">
          <span>Personal Selection</span>
          <span>{{ selectedMixedContent.length }} / {{ selectedProduct.container_size }}</span>
        </h3>
        <mixed-selection-illustration
            :quantity="selectedProduct.container_size"
            :images="selectedMixedContentImages"
        />
        <transition name="fade">
          <transition-group name="list" tag="div" v-if="selectedMixedContent.length"
                            class="wine-club-card__package-content wine-club-package-content">
            <div
                v-for="group in groupedMixedContent"
                :key="group.product.variation_id"
                class="wine-club-package-content__item"
            >
              <div class="wine-club-package-content__item-label">
                {{ group.product.title }} {{ group.product.vintage && ' - ' + group.product.vintage.name }}
              </div>
              <div class="wine-club-package-content__item-quantity">
                x{{ group.quantity }}
              </div>
              <div class="wine-club-package-content__item-regular-price">
                ${{ group.product.regular_price ? group.product.regular_price.toFixed(2) : '' }}
              </div>
              <div class="wine-club-package-content__item-package-price">
                ${{ group.product.price ? group.product.price.toFixed(2) : '' }}
              </div>
            </div>
            <div key="total" class="wine-club-package-content__item wine-club-package-content__item--total">
              <div class="wine-club-package-content__item-label">
                Total per shipment:
              </div>
              <div>
                <div class="wine-club-package-content__item-regular-price"></div>
                <div class="wine-club-package-content__item-package-price">${{ totalPrice }}</div>
              </div>
            </div>
          </transition-group>
        </transition>
        <button
            :disabled="!canProceed"
            @click="confirmSelection()"
            class="step-selection-mixed__selected-panel-cta button"
        >
          Continue
        </button>
        <button
            @click="clearSelection()"
            class="step-selection-mixed__selected-panel-clear link"
        >
          Clear selection
        </button>
      </div>
      <div v-if="selectedProduct" class="is-products">
        <mixed-product-list-item v-for="product in mixedContentOptions" :key="product.variation_id" :product="product"/>
      </div>
      <div class="step-selection-mixed__mobile-sticky">
        <div class="is-h4">{{ selectedMixedContent.length }} / {{ selectedProduct.container_size }}</div>
        <button
            :disabled="!canProceed"
            @click="confirmSelection()"
            class="link is-active"
        >
          Continue
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import MixedProductListItem from './StepSelection/MixedProductListItem.vue';
import MixedSelectionIllustration from './MixedSelectionIllustration.vue';
import {groupBy} from '@@/js/utils/utils';

export default {
  components: {
    MixedProductListItem,
    MixedSelectionIllustration,
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    selectedProduct() {
      return this.$store.state.selectedProduct;
    },
    canProceed() {
      return (
          this.selectedProduct &&
          this.$store.state.selectedMixedContent.length ===
          this.selectedProduct.container_size
      );
    },
    mixedContentOptions() {
      return this.$store.state.mixedContentOptions;
    },
    selectedMixedContent() {
      return this.$store.state.selectedMixedContent;
    },
    selectedMixedContentImages() {
      return this.$store.state.selectedMixedContent.map(c => c.image_url);
    },
    groupedMixedContent() {
      const groupedMixedContent = groupBy(this.selectedMixedContent, 'variation_id');
      const result = [];
      Object.keys(groupedMixedContent).forEach(k => {
        result.push({
          quantity: groupedMixedContent[k].length,
          product: groupedMixedContent[k][0],
        });
      });
      return result;
    },
    totalPrice() {
      return this.selectedMixedContent.reduce((res, i) => res += i.price, 0).toFixed(2);
    },
  },
  methods: {
    confirmSelection() {
      this.$store.dispatch('confirmMixedSelection');
    },
    clearSelection() {
      this.$store.commit('clearSelectedMixedContent');
    },
  },
};
</script>