export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function buildSelectReplacements(selectElement) {
  var select = $(selectElement);
  var container = select.parent().hasClass("radioSelectContainer")
    ? select.parent()
    : $("<div class='radioSelectContainer' />");
  select.after(
    select.parent().hasClass("radioSelectContainer") ? "" : container
  );
  container.addClass(select.attr("id"));
  container.append(select);

  select.find("option").each(function(optionIndex, optionElement) {
    if ($(this).val() == "") return;
    var label = $("<label />");
    container.append(label);

    $(
      "<span class='radioControl' data-value='" +
        $(this).val() +
        "'>" +
        $(this).text() +
        "</span>"
    ).appendTo(label);
  });
}
