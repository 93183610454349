<template>
  <div class="single-product-wine">
    <div v-if="product.display_variety" class="single-product-wine__variety">{{ product.variety }}</div>
    <h1 class="single-product-wine__title">{{ product.title }}</h1>

    <div class="single-product-wine__hero">
      <div class="single-product-wine__hero-image">
        <img
            v-for="v in vintages"
            :src="v.image_url"
            :class="{'is-active': v.vintage.slug === selectedVintage.vintage.slug}"
        />
      </div>
      <div class="single-product-wine__hero-title">
        <span v-for="n in [1, 2, 3]" :key="n">{{ product.title }}</span>
      </div>
    </div>

    <div class="container">
      <div class="single-product-wine__vintages">
        <button
            v-for="v in vintages" :key="v.vintage.slug"
            @click="selectVintage(v)"
            class="single-product-wine__vintage"
            :class="{
                  'is-active': v.vintage.slug === selectedVintage.vintage.slug
                }"
        >
          <span>{{ v.vintage.name }}</span>
        </button>
      </div>

      <div class="single-product-wine__body">
        <div class="single-product-wine__info">
          <div class="single-product-wine__region">
            {{ product.collection }}<br/>{{ product.region }}, {{ product.country }}
          </div>
          <div class="single-product-wine__description content"
               v-html="selectedVintage.description" />
          <div class="single-product-wine__awards">
            <div class="single-product-wine__award"  v-if="selectedVintage.awards"
                 v-for="item in selectedVintage.awards"
                 :key="item.label">
              <svg><use xlink:href="#award" /></svg>
              <div>{{ item.score }}</div>
              <div>{{ item.label }}</div>
            </div>
          </div>
        </div>
        <div class="single-product-wine__data">
          <div class="single-product-wine__details">
            <transition name="fade" mode="out-in">
              <div v-if="isSelectedVintagePurchaseable" key="vintage-in-stock">
                <div class="single-product-wine__price product-page__price">
                  <span class="single-product-wine__price-old" v-if="selectedVintage.regular_price !== selectedVintage.price">
                    <span class="single-product-wine__price-sub">$&nbsp;</span>
                    <span class="single-product-wine__price-value">{{ selectedVintage.regular_price }}</span>
                  </span>
                  <span class="single-product-wine__price-sub">$&nbsp;</span>
                  <span class="single-product-wine__price-value">{{ selectedVintage.price }}</span>
                  <span class="single-product-wine__price-sub">&nbsp;/&nbsp;per&nbsp;bottle</span>
                </div>
                <div
                    v-if="
                        userRoles &&
                        (userRoles[0] === 'foundation_wine_member' || userRoles[0] === 'tantalus_wine_club_member')
                      "
                    class="single-product-wine__special-price-note"
                >
                  *special price for
                  <span v-if="userRoles[0] === 'foundation_wine_member'">Foundation Wine Member</span>
                  <span v-if="userRoles[0] === 'tantalus_wine_club_member'">Tantalus Wine Club Member</span>
                </div>
                <div class="single-product-wine__form">
                  <div class="qty">
                    <button
                        @click="handleAddToCartDecrementQuantity()"
                        :class="{
                            'is-disabled':
                              addToCartQuantity === 1
                          }"
                    >
                      <svg><use xlink:href="#minus"></use></svg>
                    </button>
                    <input
                        v-model.number="addToCartQuantity"
                        type="number"
                        readonly
                        min="1"
                        @blur="handleAddToCartQuantityBlur()"
                    />
                    <button
                        :class="{
                            'is-disabled':
                              addToCartQuantity >= allowedLimitToAddToCartPerVariation[selectedVintage.variation_id]
                          }"
                        @click="handleAddToCartIncrementQuantity()"
                    >
                      <svg><use xlink:href="#plus"></use></svg>
                    </button>
                  </div>
                  <button
                      @click="handleAddtoCartClick()"
                      class="button"
                      :disabled="addToCartQuantity > allowedLimitToAddToCartPerVariation[selectedVintage.variation_id]"
                  >
                    {{ addToCartLabel }}
                  </button>
                </div>
                <div
                    v-if="selectedVintage.stock_status === 'preorder'"
                    class="single-product-wine__preorder-message"
                >
                  Preorder orders are shipped when the product becomes available.
                </div>
                <div
                    v-if="addToCartQuantity >= allowedLimitToAddToCartPerVariation[selectedVintage.variation_id]"
                    class="single-product-wine__max-per-order-message"
                >
                  {{ product.title + (product.product_max_quantity_per_order ? '' : ' ' + selectedVintage.vintage.name) }}
                  is limited to
                  {{ product.product_max_quantity_per_order || selectedVintage.variation_max_quantity_per_order }}
                  {{
                    (product.product_max_quantity_per_order || selectedVintage.variation_max_quantity_per_order) === 1 ?
                        'bottle' :
                        'bottles'
                  }}
                  per order
                </div>
                <transition name="fade">
                  <div v-if="addToCartQuantity >= 3 && !isUserClubMemeber" class="single-product-wine__wine-club-message">
                    Loving our wine? Become a member of our <a class="is-active-border-link" target="_blank" :href="wineClubUrl">Tantalus Wine Club</a>
                    and receive special offers and prices.
                  </div>
                </transition>
              </div>
              <div
                  v-else-if="selectedVintage.stock_status === 'outofstock'"
                  key="vintage-out-of-stock"
                  class="single-product-wine__out-of-stock-status"
              >
                Sold Out
              </div>
              <div
                  v-else-if="selectedVintage.stock_status === 'futurerelease'"
                  key="vintage-future-release"
                  class="single-product-wine__out-of-stock-status"
              >
                Future release, available soon
              </div>
              <div
                  v-else-if="selectedVintage.stock_status === 'onallocationonly'"
                  key="vintage-allocation"
                  class="single-product-wine__out-of-stock-status"
              >
                On allocation only
              </div>
              <div
                  v-else-if="notAllowedByRoleMessage"
                  key="not-available-by-role"
                  class="single-product-wine__out-of-stock-status"
              >
                {{ notAllowedByRoleMessage }}
              </div>
            </transition>
          </div>

          <div class="single-product-wine__report" data-accordion>
            <details v-if="selectedVintage.vintage_report">
              <summary><span>Vintage report, {{ selectedVintage && selectedVintage.vintage.name }}</span><i /></summary>
              <div class="content" v-html="selectedVintage && selectedVintage.vintage_report || ''" />
            </details>
            <details>
              <summary><span>Technical analysis</span><i /></summary>
              <div>
                <div
                    v-for="item in selectedVintage.technical_analysis"
                    :key="item.label"
                    class="single-product-wine__tech-analysis"
                >
                  <div>{{ item.label }}</div>
                  <div>{{ item.value }}</div>
                </div>
                <a
                    v-if="selectedVintage.technical_sheet_url"
                    :href="selectedVintage.technical_sheet_url"
                    target="_blank"
                    class="link is-active"
                >Download technical sheet</a>
              </div>
            </details>
            <details v-if="selectedVintage.winemaking">
              <summary><span>Winemaking</span><i /></summary>
              <div class="content" v-html="selectedVintage.winemaking" />
            </details>
            <details v-if="selectedVintage.food_pairing">
              <summary><span>Food pairing</span><i /></summary>
              <div class="content" v-html="selectedVintage.food_pairing" />
            </details>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addToCart} from '@@/js/components/cart';

export default {
  name: 'SingleProductWine',
  props: [
    'product',
    'vintages',
    'defaultVintageSlug',
    'shopUrl',
    'categoryListUrl',
    'userRoles',
  ],
  components: {
  },
  data() {
    // if landing with variation link
    const queryParams = new URLSearchParams(window.location.search);
    const queryVintage = queryParams && queryParams.get('attribute_pa_vintage');
    const initialVingateSlug = queryVintage || this.defaultVintageSlug;
    return {
      addToCartQuantity: 1,
      selectedVintage: this.vintages.find(v => v.vintage.slug === initialVingateSlug) || this.vintages[0],
      isImageLoaded: {},
      isImageDisplayed: true,
      notAllowedByRoleMessage: null,
      isSelectedVintagePurchaseable: false,
      analyticsProductsList: 'Recommended Products List',
      allowedLimitToAddToCartPerVariation: {},
      wineClubUrl: window.TANTALUS.wine_club_url,
    };
  },
  computed: {
    isUserClubMemeber() {
      return this.userRoles.includes('foundation_wine_member') ||
          this.userRoles.includes('tantalus_wine_club_member');
    },
    addToCartLabel() {
      return this.selectedVintage.stock_status === 'preorder' ? 'Preorder' : 'Add to Cart';
    },
  },
  mounted() {
    this.selectVintage(this.selectedVintage, true);
    setTimeout(() => {
      if (window.cartApp && window.cartApp.$store) {
        if (window.cartApp.$store.getters['cart/items']) {
          this.updateAddedToCartQuantity(window.cartApp.$store.getters['cart/items']);
        }

        window.cartApp.$store.watch(state => state.cart.items, (newValue) => {
          this.updateAddedToCartQuantity(newValue);
        });
      }

      this.trackRecommendedImpressions();
    });
  },
  methods: {
    selectVintage(v, isInitial = false) {
      this.selectedVintage = v;
      this.isImageDisplayed = false;
      this.addToCartQuantity = 1;
      this.updateIsVintagePurchaseable(v);
      setTimeout(() => {
        this.isImageDisplayed = true;
      }, 1000);
      if (!isInitial) {
        this.trackVariationDetailChange();
      }
    },
    updateAddedToCartQuantity(cartItems) {
      const quantityPerVariationInCart = {};
      cartItems.forEach(i => {
        if (i.category_slug === 'wine') {
          quantityPerVariationInCart[i.variation_id] = i.quantity;
        }
      });
      const allowedLimitToAddToCartPerVariation = {};
      this.vintages.forEach(v => {
        const limitPerOrder = this.product.product_max_quantity_per_order || v.variation_max_quantity_per_order;
        allowedLimitToAddToCartPerVariation[v.variation_id] =
            limitPerOrder === 0
                ? 9999
                : limitPerOrder - (quantityPerVariationInCart[v.variation_id] || 0);
      });
      this.allowedLimitToAddToCartPerVariation = allowedLimitToAddToCartPerVariation;
    },
    updateIsVintagePurchaseable(v) {
      let allowedByRole = true;
      this.notAllowedByRoleMessage = null;
      if (this.userRoles.includes('foundation_wine_member') && v.not_available_for_foundation_wine_members_message) {
        allowedByRole = false;
        this.notAllowedByRoleMessage = v.not_available_for_foundation_wine_members_message;
      } else if (
          !this.userRoles.includes('foundation_wine_member') &&
          this.userRoles.includes('tantalus_wine_club_member') &&
          v.not_available_for_tantalus_wine_club_members_message
      ) {
        allowedByRole = false;
        this.notAllowedByRoleMessage = v.not_available_for_tantalus_wine_club_members_message;
      } else if (
          !this.userRoles.includes('foundation_wine_member') &&
          !this.userRoles.includes('tantalus_wine_club_member') &&
          v.not_available_for_cellar_door_message
      ) {
        allowedByRole = false;
        this.notAllowedByRoleMessage = v.not_available_for_cellar_door_message;
      }
      this.isSelectedVintagePurchaseable = v.stock_status === 'instock' || v.stock_status === 'preorder' &&
          v.is_in_stock && allowedByRole;
    },
    handleAddToCartIncrementQuantity() {
      if (this.addToCartQuantity >= this.allowedLimitToAddToCartPerVariation[this.selectVintage.variation_id]) {
        return;
      }
      this.addToCartQuantity++;
    },
    handleAddToCartDecrementQuantity() {
      if (this.addToCartQuantity > 1) {
        this.addToCartQuantity--;
      }
    },
    handleAddToCartQuantityBlur() {
      this.addToCartQuantity = Math.min(Math.max(parseInt(this.addToCartQuantity), 1), 100);
    },
    handleAddtoCartClick() {
      if (document.body.classList.contains('is-product-loading') ||
          this.addToCartQuantity >= this.allowedLimitToAddToCartPerVariation[this.selectVintage.variation_id]
      ) {
        return;
      }

      addToCart(this.selectedVintage.variation_id || this.product.product_id, this.addToCartQuantity)
    },
    trackRecommendedImpressions() {
      window[ gtm4wp_datalayer_name ]?.push({
        'event': 'gtm4wp.productImpressionEEC',
        'ecommerce': {
          'currencyCode': gtm4wp_currency,
          'impressions': this.recommendedProducts.map((p, i) => ({
            'name': p.title,
            'id': p.id,
            'price': p.price,
            'category': 'Wine',
            'position': i,
            'list': this.analyticsProductsList
          })
        )}
      });
    },
    trackVariationDetailChange() {
      window[ gtm4wp_datalayer_name ]?.push({
        'event': 'gtm4wp.changeDetailViewEEC',
        'ecommerce': {
          'currencyCode': gtm4wp_currency,
          'detail': {
            'products': [{
              'name': this.product.title,
              'id': this.product.product_id,
              'price': this.selectedVintage.price,
              'category': 'Wine',
              'variant': this.selectedVintage.vintage.name
            }]
          },
        },
        'ecomm_prodid': gtm4wp_id_prefix + this.selectedVintage.variation_id,
        'ecomm_pagetype': 'product',
        'ecomm_totalvalue': this.selectedVintage.price,
      })
    },
  },
};
</script>
