import Vue from 'vue'
import SingleProductWineComponent from '@@/js/vue/SingleProductWine.vue';
import {addToCart} from '@@/js/components/cart';
const button = document.querySelector('[data-add-to-cart]');
const form = document.querySelector('form.cart');
const wineProduct = document.querySelector('.single-product-wine');
const variationId = document.querySelector('[name="variation_id"]');
const variationsJson = JSON.parse(document.querySelector('[data-variations]')?.textContent || '{}');
const dataVariationSelects = document.querySelectorAll('[data-variation-select]');
const selectedVariation = {};

Vue.component('single-product-wine', SingleProductWineComponent)

/**
 * Replace the default select with a custom select
 */
dataVariationSelects && dataVariationSelects.forEach(el => {
  const select = el.querySelector('select');
  const div = document.createElement('div');
  div.classList.add('wl-select');
  div.appendChild(select.cloneNode(true));

  const placeholder = document.createElement('div');
  placeholder.textContent = select.options[0].textContent;
  div.appendChild(placeholder);

  const ul = document.createElement('ul');
  Array.from(select.options).slice(1).forEach(option => {
    const li = document.createElement('li');
    li.textContent = option.textContent;
    li.dataset.value = option.value;
    ul.appendChild(li);
  });

  div.appendChild(ul);
  el.replaceWith(div);

    div.querySelector('select').addEventListener('change', (e) => {
      selectedVariation[e.target.name] = e.target.value;

      if (Object.keys(selectedVariation).length === dataVariationSelects.length) {
        // find variationsJson that matches selectedVariation
        const variation = variationsJson.find(variation => {
          return Object.keys(selectedVariation).every(key => {
            return selectedVariation[key] === variation.attributes[key];
          });
        });

        variationId.value = variation && variation.is_in_stock ? variation.variation_id : 0;
      }
    })
});

form?.addEventListener('submit', (e) => {
  e.preventDefault();
  let giftCardData = {};
  let giftCardTo = form.querySelector('[name="pw_gift_card_to"]');
  if (giftCardTo) {
    giftCardData['pw_gift_card_to'] = giftCardTo.value;
    giftCardData['pw_gift_card_recipient_name'] = form.querySelector('[name="pw_gift_card_recipient_name"]').value;
    giftCardData['pw_gift_card_from'] = form.querySelector('[name="pw_gift_card_from"]').value;
    giftCardData['pw_gift_card_message'] = form.querySelector('[name="pw_gift_card_message"]').value;
  }
  addToCart(
      (form.querySelector('[name="variation_id"]') || form.querySelector('[name="product_id"]')).value,
      form.querySelector('[name="quantity"]').value,
      new URLSearchParams(giftCardData).toString(),
  );
});


if (document.querySelector(".js-single-product-wine")) {
  new Vue({}).$mount('#single-product-wine')
}

