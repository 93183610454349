import {updateCart} from '@@/js/components/cart';

document.addEventListener('click', e => {
  const quantity = e.target?.closest('.quantity');

  if (quantity && e.target.matches('button')) {
    const input = quantity.querySelector('input');
    const cart = quantity.closest('.woocommerce-cart-form');
    // const minus = e.target.is('button:first-of-type');
    // const plus =  e.target.is('button:last-of-type');

    // check if the target is a button and if it is the first or last button
    if (e.target.previousElementSibling) {
      input.value = parseInt(input.value) + 1;
    } else {
      input.value = Math.max(1, parseInt(input.value) - 1);
    }

    cart && updateCart();
  }
})