<template>
  <div
      class="product-tile-wine product-tile-wine--wine-club-mixed-item"
      :class="{
      'is-selected': productQuantityInSelected > 0,
      'is-in-stock': isInStock,
    }"
  >
    <div class="product-tile-wine__thumb-container">
      <img
          class="product-tile-wine__thumb"
          :class="{
          'product-tile-wine__thumb--loaded': isImageLoaded
        }"
          :src="product.image_url"
          @load="handleImageLoaded()"
      />
      <!--      <div class="product-tile-wine__region">-->
      <!--        {{ product.region || '' }}-->
      <!--      </div>-->
    </div>
    <div class="product-tile-wine__variety">
      {{ product.variety }}
    </div>
    <div class="product-tile-wine__title" v-html="productTitle"></div>
    <div class="product-tile-wine__price-qty">
      <div class="product-tile-wine__amount qty">
        <button @click="handleRemove" :disabled="!canRemove">
          <svg>
            <use xlink:href="#minus"/>
          </svg>
        </button>
        <input type="text" readonly :value="productQuantityInSelected">
        <button @click="handleAdd" :disabled="!canAdd">
          <svg>
            <use xlink:href="#plus"/>
          </svg>
        </button>
      </div>
      <div v-if="isInStock" class="product-tile-wine__price">
      <span class="product-tile-wine__price-before-discount">
        ${{ product.regular_price ? product.regular_price.toFixed(2) : '' }}
      </span>
        ${{ product.price ? product.price.toFixed(2) : '' }}
      </div>
    </div>
    <div v-if="!isInStock" class="product-tile-wine__not-available-note">
      Out of stock
    </div>
    <div v-if="hasReachedMaxPerProduct" class="product-tile-wine__not-available-note">
      Limited to {{ product.product_max_quantity_per_order }} per shipment
    </div>
    <div v-if="hasReachedMaxPerVariation" class="product-tile-wine__not-available-note">
      Limited to {{ product.variation_max_quantity_per_order }} per shipment
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isImageLoaded: false,
    };
  },
  computed: {
    productTitle() {
      return `${this.product.title}${this.product.vintage && ' ' + this.product.vintage.name}`;
    },
    selectedMixedProduct() {
      return this.$store.state.selectedProduct;
    },
    selectedMixedContent() {
      return this.$store.state.selectedMixedContent;
    },
    isInStock() {
      return (
          this.product.stock_status === 'instock' && this.product.is_in_stock
      );
    },
    productQuantityInSelected() {
      return this.selectedMixedContent.filter(
          (p) => p.variation_id === this.product.variation_id,
      ).length;
    },
    hasReachedMaxPerProduct() {
      return (
          this.product.product_max_quantity_per_order &&
          this.product.product_max_quantity_per_order ===
          this.productQuantityInSelected
      );
    },
    hasReachedMaxPerVariation() {
      return (
          this.product.variation_max_quantity_per_order &&
          this.product.variation_max_quantity_per_order ===
          this.productQuantityInSelected
      );
    },
    canAdd() {
      return (
          this.selectedMixedContent.length <
          this.selectedMixedProduct.container_size &&
          !this.hasReachedMaxPerProduct &&
          !this.hasReachedMaxPerVariation &&
          this.isInStock
      );
    },
    canRemove() {
      return this.productQuantityInSelected > 0;
    },
  },
  methods: {
    handleImageLoaded() {
      this.isImageLoaded = true;
    },
    handleAdd() {
      if (!this.canAdd) {
        return;
      }
      this.$store.dispatch('addMixedProduct', this.product);
    },
    handleRemove() {
      if (!this.canRemove) {
        return;
      }
      this.$store.dispatch('removeMixedProduct', this.product);
    },
  },
};
</script>