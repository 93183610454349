document.body.addEventListener('click', function (e) {
  if (e.target.id === 'apply_coupon') {
    let data = {
      security: wc_checkout_params.apply_coupon_nonce,
      coupon_code: document.querySelector('input[name="coupon_code"]').value
    };

    fetch(wc_checkout_params.wc_ajax_url.toString().replace('%%endpoint%%', 'apply_coupon'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(data)
    })
    .then(response => response.text())
    .then(code => {
      const message = document.querySelector('[data-coupon-message]');
      message.innerHTML = '';
      if (code) {
        message.innerHTML = code;
        setTimeout(() => {
          jQuery(document.body).trigger('update_checkout');
        }, 500)
      }
    })
    .catch(error => console.error('Error:', error));
  }
})