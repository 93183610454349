import KeenSlider from 'keen-slider'

class WlKeenSlider extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const isAutoplay = this.hasAttribute('autoplay')
    const pauseOnMouseEnter = this.hasAttribute('autoplay-pause-on-mouse-enter')

    function autoplay(slider) {
      if (!isAutoplay) {
        return
      }

      let timeout
      let mouseOver = false

      function clearNextTimeout() {
        clearTimeout(timeout)
      }

      function nextTimeout() {
        clearTimeout(timeout)
        if (mouseOver) return
        timeout = setTimeout(() => {
          slider.next()
        }, 4000)
      }

      slider.on("created", () => {
        if (pauseOnMouseEnter) {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
        }
        nextTimeout()
      })

      slider.on("dragStarted", clearNextTimeout)
      slider.on("animationEnded", nextTimeout)
      slider.on("updated", nextTimeout)
    }

    function navigation(slider) {
      if (!slider.slides.length || slider.slides.length === 1) return

      let wrapper, dots, arrowLeft, arrowRight

      function markup(remove) {
        // wrapperMarkup(remove)
        // dotMarkup(remove)
        arrowMarkup(remove)
      }

      function removeElement(elment) {
        elment.parentNode.removeChild(elment)
      }

      function createDiv(className) {
        let div = document.createElement("div")
        let classNames = className.split(" ")
        classNames.forEach((name) => div.classList.add(name))
        return div
      }

      const arrowMarkup = (remove) => {
        if (remove) {
          removeElement(arrowLeft)
          removeElement(arrowRight)
          return
        }
        arrowLeft = createDiv("keen-slider__arrow is-left")
        arrowLeft.innerHTML = '<svg><use xlink:href="#arrow-left"></use></svg>'
        arrowLeft.addEventListener("click", () => slider.prev())
        arrowRight = createDiv("keen-slider__arrow is-right")
        arrowRight.innerHTML = '<svg><use xlink:href="#arrow-right"></use></svg>'
        arrowRight.addEventListener("click", () => slider.next())
        slider.container.parentElement.appendChild(arrowLeft)
        slider.container.parentElement.appendChild(arrowRight)
      }

      // function wrapperMarkup(remove) {
      //   if (remove) {
      //     let parent = wrapper.parentNode
      //     while (wrapper.firstChild)
      //       parent.insertBefore(wrapper.firstChild, wrapper)
      //     removeElement(wrapper)
      //     return
      //   }
      //   wrapper = createDiv("navigation-wrapper")
      //   slider.container.parentNode.appendChild(wrapper)
      //   wrapper.appendChild(slider.container)
      // }

      // function dotMarkup(remove) {
      //   if (remove) {
      //     removeElement(dots)
      //     return
      //   }
      //   dots = createDiv("keen-slider__dots")
      //   slider.track.details.slides.forEach((_e, idx) => {
      //     let dot = createDiv("keen-slider__dot")
      //     dot.addEventListener("click", () => slider.moveToIdx(idx))
      //     dots.appendChild(dot)
      //   })
      //   wrapper.appendChild(dots)
      // }

      function updateClasses() {
        let slide = slider.track.details.rel
        slide === 0
          ? arrowLeft.classList.add("is-disabled")
          : arrowLeft.classList.remove("is-disabled")
        slide === slider.track.details.slides.length - 1
          ? arrowRight.classList.add("is-disabled")
          : arrowRight.classList.remove("is-disabled")
        // Array.from(dots.children).forEach(function (dot, idx) {
        //   idx === slide
        //     ? dot.classList.add("is-active")
        //     : dot.classList.remove("is-active")
        // })
      }

      slider.on("created", () => {
        markup()
        updateClasses()
      })
      slider.on("optionsChanged", () => {
        markup(true)
        markup()
        updateClasses()
      })
      slider.on("slideChanged", () => {
        updateClasses()
      })
      slider.on("destroyed", () => {
        markup(true)
      })
    }

    const isSingle = this.hasAttribute('single')

    let slider = new KeenSlider(this.querySelector('.keen-slider'), {
      loop: true,
      rtl: false,
      mode: "snap",
      slides: { perView: "auto" },
    }, [navigation, autoplay])
  }

  disconnectedCallback() {
  }
}

customElements.define('keen-slider', WlKeenSlider);
