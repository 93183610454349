<template>
  <div class="container">
    <div class="wine-club-step-type wine-club-cards-container">
      <div
        v-for="o in frequencyOptions"
        :key="o"
        class="wine-club-card"
        :class="{ 'is-active': selectedFrequency === o }"
      >
        <div class="wine-club-card__content wine-club-card__content--with-cta">
          <h3 class="wine-club-card__title is-h2">
            Every {{ o }} months
          </h3>
          <img
            class="wine-club-card__img wine-club-card__img--small"
            :class="{
              'wine-club-card__img--loaded': isImageLoaded
            }"
            :src="`${baseAssetsUrl}/images/wine-club/frequency-${o}.png`"
            :alt="`Every ${o} months`"
            @load="handleImageLoaded()"
          />
          <button class="wine-club-card__cta" @click="selectFrequency(o)">
            {{ selectedFrequency === o ? 'Selected' : 'Select' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseAssetsUrl: window.TANTALUS.asssets_base_url,
      isImageLoaded: false
    }
  },
  computed: {
    frequencyOptions() {
      return this.$store.state.frequencyOptions
    },
    selectedFrequency() {
      return this.$store.state.selectedFrequency
    }
  },
  methods: {
    selectFrequency(option) {
      this.$store.dispatch("selectFrequency", option);
    },
    handleImageLoaded() {
      this.isImageLoaded = true
    }
  },
}
</script>