import {setCookie} from "@@/js/utils/cookies.js";
import {delay} from "@@/js/utils/delay";

const ageGate = document.querySelector('.age-gate');
const preloader = document.querySelector('.preloader');

if (ageGate) {
  const btn = ageGate.querySelector('button');

  btn.addEventListener('click', () => {
    setCookie('age-gate', true)
    document.body.classList.add('is-loaded');
    ageGate.classList.add('is-added')
    window.scrollTo(0, 0)
    delay(300).then(() => {
      ageGate.remove()
    });

    delay(4000).then(() => {
      preloader && preloader.remove()
    });
  });
}


