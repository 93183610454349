<template>
  <div class="container">
    <div class="wine-club-step-type wine-club-cards-container">
      <div
        v-for="prepackProduct in prepackProductOptions"
        :key="prepackProduct.product_id"
        class="wine-club-card"
        :class="{ 'wine-club-card--active': selectedProduct && selectedProduct.product_id === prepackProduct.product_id }"
      >
        <div
          class="wine-club-card__content wine-club-card__content--with-cta"
          :style="{'border-color': prepackProduct.color}"
        >
          <h3 class="wine-club-card__title">
            {{ prepackProduct.title }}
          </h3>
          <div class="wine-club-card__description">
            {{ prepackProduct.description }}
          </div>
          <div>
            <button class="wine-club-card__cta" @click="selectType(TYPE_PREPACK, prepackProduct)">
              {{ selectedProduct && selectedProduct.product_id === prepackProduct.product_id ? 'Selected' : 'Select' }}
            </button>
            <button class="wine-club-card__footer-link" @click="activatePrepackDetailsPopup(prepackProduct)">
              Preview Selection
            </button>
          </div>
        </div>
      </div>
      <div
        class="wine-club-card"
        :class="{ 'wine-club-card--active': isSelectedMixed }"
        key="personal"
      >
        <div
          class="wine-club-card__content"
          :style="{'border-color': commonSettings.mixedColor}"
        >
          <h3 class="wine-club-card__title">
            {{ commonSettings.mixedTitle }}
          </h3>
          <div class="wine-club-card__description">
            {{ commonSettings.mixedDescription }}
          </div>
          <button class="wine-club-card__cta" @click="selectType(TYPE_MIXED)">
            {{ isSelectedMixed ? 'Selected' : 'Select' }}
          </button>
        </div>
      </div>
      <div v-if="!commonSettings.isFoundationMember" key="fwcCta" class="wine-club-card">
        <div
          class="wine-club-card__content"
          :style="{'border-color': commonSettings.fwcColor}"
        >
          <h3 class="wine-club-card__title">
            {{ commonSettings.fwcTitle }}
          </h3>
          <div class="wine-club-card__description">
            {{ commonSettings.fwcDescription }}
          </div>
          <a :href="commonSettings.fwcButtonLink" class="wine-club-card__cta">
            {{ commonSettings.fwcButtonLabel }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_MIXED, TYPE_PREPACK } from "../store";

export default {
  data() {
    return {
      TYPE_PREPACK,
      TYPE_MIXED,
    };
  },
  computed: {
    prepackProductOptions() {
      return this.$store.state.prepackProductOptions;
    },
    commonSettings() {
      return this.$store.state.commonSettings;
    },
    selectedType() {
      return this.$store.state.selectedType;
    },
    selectedProduct() {
      return this.$store.state.selectedProduct;
    },
    isSelectedMixed() {
      return this.selectedType === TYPE_MIXED;
    }
  },
  methods: {
    selectType(type, product) {
      this.$store.dispatch("selectType", { type, product });
    },
    activatePrepackDetailsPopup(product) {
      this.$store.dispatch("activatePrepackDetailsPopup", product);
    }
  },
};
</script>