import {setCookie} from "@@/js/utils/cookies.js";
import {delay} from "@@/js/utils/delay";

const notice = document.querySelector('.privacy-notice');

if (notice) {
  const btns = notice.querySelectorAll('button');
  btns.forEach(btn => {
    btn.addEventListener('click', () => {
      setCookie('privacy', true)
      notice.classList.add('is-added')
      delay(300).then(() => {
        notice.remove()
      });
    });
  })
}


