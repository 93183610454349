const preloader = document.querySelector('.preloader');
const ageGate = document.querySelector('.age-gate');

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}


if (!ageGate && preloader) {
 document.body.classList.add('is-loaded');
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100)

 setTimeout(() => {
    preloader.remove();
 }, 4000)
}
