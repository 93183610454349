function getSamePageAnchor(link) {
  if (
      link.protocol !== window.location.protocol ||
      link.host !== window.location.host ||
      link.pathname.replace(/^\/|\/$/g, '') !== window.location.pathname.replace(/^\/|\/$/g, '') ||
      link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

export function scrollToHash(hash, e) {
  const elem = hash ? document.querySelector(hash) : document.documentElement;
  if (elem) {
    if (e) {
      e.preventDefault();
    }

    const offset = document.querySelector('.header').offsetHeight;
    const y = elem.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({
      top: y,
      behavior: 'smooth'
    });
  }
}

export function initScrollLinks() {
  document.querySelectorAll("a[href*='#']").forEach((a) => {
    a.addEventListener('click', (e) => {
      if (e.target.hostname !== window.location.hostname) {
        return;
      }

      scrollToHash(getSamePageAnchor(a), e);
    });
  });
}

initScrollLinks()
