<template>
  <transition name="fade">
    <div id="prepackDetailsPopup" class="prepack-details-popup" v-if="prepackDetailsPopupProduct">
      <div class="prepack-details-popup__bg"></div>
      <div class="prepack-details-popup__box">
        <div 
          class="prepack-details-popup__box-content"
          :style="{'border-color': prepackDetailsPopupProduct.color}"
        >
          <h3 class="has-text-centered">{{ prepackDetailsPopupProduct.title }}</h3>

          <div class="prepack-details-popup__tabs">
            <button
              v-for="(variation, i) in prepackDetailsPopupProduct.variations" :key="i"
              class="prepack-details-popup__tab"
              :class="{'is-active': i === activeTabIndex}"
              @click="setActiveTabIndex(i)"
            >
              {{ variation.quantity }} bottles every {{ variation.frequency }} months
            </button>
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="activeTabVariation" :key="activeTabIndex" class="columns is-spacing-sized-sm">
              <div class="prepack-details-popup__frequency-item column is-12">
                <div class="prepack-details-popup__frequency-item-content">
                  <div
                    v-for="(pci, pciIndex) in activeTabVariation.package_content"
                    :key="pciIndex"
                    class="prepack-details-popup__frequency-item-content-item"
                  >
                    <div class="prepack-details-popup__frequency-item-content-item-title">
                      {{ pci.subscription_package_content_variety_note }}
                      <span v-if="pci.subscription_package_content_is_current">&nbsp;(shipping currently)</span>
                    </div>
                    <div class="wine-club-package-content">
                      <div
                        v-for="(item, index) in pci.subscription_package_content_items"
                        :key="index"
                        class="wine-club-package-content__item"
                      >
                        <div class="wine-club-package-content__item-label">
                          {{ item.subscription_package_content_item_title || '-' }}
                        </div>
                        <div class="wine-club-package-content__item-quantity">
                          {{ item.subscription_package_content_item_quantity ? `x${item.subscription_package_content_item_quantity}` : '' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="prepack-details-popup__frequency-item-price">
                  <span>${{ activeTabVariation.price }}</span> per shipment
                </div>
              </div>
            </div>
          </transition>

          <button class="wine-club-card__footer-link has-marg-top-xl has-marg-bottom-xl" @click="deactivatePrepackDetailsPopup()">
            Close
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      activeTabIndex: 0,
    };
  },
  computed: {
    commonSettings() {
      return this.$store.state.commonSettings;
    },
    isFoundationMember() {
      return this.commonSettings.isFoundationMember;
    },
    bottlesPerShipment() {
      return this.commonSettings.isFoundationMember ? 12 : 6;
    },
    bottlesPerShipmentLabel() {
      if (this.isFoundationMember) {
        return '12 or 18';
      } else {
        return '6';
      }
    },
    prepackDetailsPopupProduct() {
      return this.$store.state.prepackDetailsPopupProduct
    },
    activeTabVariation() {
      return this.prepackDetailsPopupProduct && this.prepackDetailsPopupProduct.variations && this.prepackDetailsPopupProduct.variations[this.activeTabIndex];
    }
  },
  methods: {
    deactivatePrepackDetailsPopup() {
      this.$store.dispatch("deactivatePrepackDetailsPopup");
    },
    setActiveTabIndex(i) {
      return this.activeTabIndex = i;
    },
  }
}
</script>